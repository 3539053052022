html, body {
    margin: 0;
    padding: 0;
    font-family: 'Open Sans', sans-serif;
    height: 100%;
}

body {
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    height: 100%;
    background-color: #e0eaf4 !important;
}

div#root{
   /* display:flex; */
    flex-direction: column;
    height:100%;
}

div#wcx-chat{
    z-index: 99999 !important;
    position: absolute;
}

a {
    cursor: pointer;
    text-decoration: none;
}

a.white-link {
    color: rgba(0, 0, 0, 0.87);
}

.grecaptcha-badge {
    display: none !important;
}

.PhoneInputCountry{
    padding-top: 15px;
    padding-left: 50px;
}

input.PhoneInputInput {
    height: 32px;
    margin: 0;
    display: block;
    padding: 6px 12px 6px 0;
    border: none;
    border-bottom: 1px solid #949494;
    margin-top: 15px;
}

input.PhoneInputInput:focus {
    outline: 0;
    border-bottom: 2px solid #2196f3;
}

::-webkit-scrollbar {
    width: 5px;
    
}

/* Track */
::-webkit-scrollbar-track {
    background:  #f1f1f1; 
    border-radius: 50px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #d3d3d3;
    border-radius: 50px;

}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #939daa;
}


/** Carrusel contenidos => contenido-with-carousel  ---------------------------------------------------*/
.carousel-container {
    width: 100%;
    max-width: none; /* Elimina el límite predeterminado de ancho */
    padding: 0 15px; /* Agrega un pequeño padding horizontal */
}
.carousel-indicators {
    position: absolute;
    bottom: 10px; /* Ajustar la posición vertical */
    left: 50%;
    /* transform: translateX(-50%); */
    display: flex;
    /*gap: 2px; /* Espaciado entre los puntos */
    margin-bottom: 5px !important
}

.content-carousel-indicators button {
    width: 12px !important; /* Tamaño del círculo */
    height: 12px !important; /* Tamaño del círculo */
    border-radius: 50%; /* Hace los indicadores redondos */
    background-color: #000; /* Color de los puntos */
    border: none;
    opacity: 0.5; /* Puntos inactivos más transparentes */
    transition: opacity 0.3s ease;
}

.content-carousel-indicators button.active {
    opacity: 1; /* Punto activo completamente visible */
    background-color: #fff; /* Color del punto activo */
}

.content-carousel-control-prev,
.content-carousel-control-next {
    width: 22px !important; /* Ajusta el ancho del botón */
    height: 22px !important; /* Ajusta la altura del botón */
    border-radius: 50%; /* Botón redondeado */
    display: flex;
    justify-content: center;
    align-items: center;
    /*transition: background-color 0.3s ease; /* Animación en hover */
    border: none; /* Elimina bordes predeterminados */
    top: 50% !important; /* Centra verticalmente el botón */
    transform: translateY(-50%); /* Ajusta la posición para centrarlo exactamente */
    z-index: 10; /* Asegura que los botones estén sobre las imágenes */
    margin: 8px !important;
}

.content-carousel-control-prev-icon,
.content-carousel-control-next-icon {
    background-color: transparent; /* Fondo transparente para los iconos */
    /*filter: invert(1); /* Invierte los colores para icono blanco */
    width: 16px !important; /* Ajusta el tamaño del icono */
    height: 16px !important;
}
.content-carousel-control-prev-icon {
    /* grosor icono: stroke-width*/
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="rgba(0,38,76,1)" stroke-width="2" viewBox="0 0 8 8"%3E%3Cpath d="M5 1L1 4l4 3" /%3E%3C/svg%3E') !important;
}
/* Icono del botón "next" */
.content-carousel-control-next-icon {
    /* grosor icono: stroke-width*/
    background-image: url('data:image/svg+xml;charset=UTF-8,%3Csvg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="rgba(0,38,76,1)" stroke-width="2" viewBox="0 0 8 8"%3E%3Cpath d="M3 1l4 3-4 3" /%3E%3C/svg%3E') !important;
}

.content-carousel {
    float: right;
    margin-left: 30px !important;
    margin-bottom: 20px !important;
    width: 50% !important; 
    align-content: center;
    border-color: rgba(0,38,76,1);
    border: solid;
    border-width: 1px !important;
}
  
.row {
    display: flex;
    flex-direction: row;
    align-items: center;
    
}
.cell {
    padding: 10px !important;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50% !important;
}

.cell-text {
    margin-left: 10px !important;
    margin-right: 10px !important;

}

  

/** ---------------------------------------------------------------------------------------------------*/